<!--
 * @Description: 轮播
 * @Author: 琢磨先生
 * @Date: 2022-06-10 14:24:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-18 01:05:55
-->

<template>
  <el-card class="box">
    <el-form ref="query" :inline="true">
      <el-form-item label="">
        <el-select
          v-model="area_id"
          placeholder=""
          clearable
          @change="areaChange"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-button type="primary" size="small" round icon="plus" @click="onEdit()"
      >添加轮播</el-button
    >
  </el-card>
  <el-card class="box" v-loading="loading">
    <el-table :data="bannerList" border>
      <el-table-column
        label="序号"
        prop="sequence"
        width="100"
      ></el-table-column>
      <el-table-column
        label="链接类型"
        prop="link_type_text"
        width="140"
      ></el-table-column>
      <el-table-column label="图片" width="240">
        <template #default="scope">
          <el-image
            :src="scope.row.pic_url"
            fit="cover"
            style="width: 200px; height: 166px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="参数" prop="value"></el-table-column>
      <el-table-column width="180" label="操作">
        <template #default="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >

          <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="text" class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="图片" prop="pic_url">
        <upload-avatar
          :src="form.pic_url"
          :aspectRatio="750 / 625"
          @change="uploadChange"
        ></upload-avatar>
        <div class="help-block">图片要求 格式750x625 且小于3M</div>
      </el-form-item>
      <el-form-item label="城市">
        <el-select v-model="form.area_id" placeholder="" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
          </el-option>
        </el-select>
        <div class="help-block">指定城市，不指定则为默认(所有城市通用)</div>
      </el-form-item>

      <el-form-item label="链接类型" prop="link_type">
        <el-radio-group v-model="form.link_type">
          <el-radio :label="0">无</el-radio>
          <el-radio :label="1">房源</el-radio>
          <el-radio :label="2">商品</el-radio>
          <el-radio :label="3">网址</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="form.link_type"
        :label="
          form.link_type == 1
            ? '选择房源'
            : form.link_type == 2
            ? '选择商品'
            : form.link_type == 3
            ? '网址链接'
            : ''
        "
      >
        <el-select
          v-if="form.link_type == 1"
          v-model="form.value"
          placeholder=""
          remote
          clearable
          filterable
          :remote-method="remoteHouse"
        >
          <el-option
            v-for="item in houseOptions"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-col :span="18" v-if="form.link_type == 3">
          <el-input v-model="form.value" placeholder="请输入网址"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="序号(正序)" prop="sequence">
        <el-col :span="10">
          <el-input v-model="form.sequence"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import UploadAvatar from "@/views/upload/upload_avatar";

export default {
  components: {
    UploadAvatar,
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      title: "",
      area_id: "",
      //城市
      areaList: [],
      //房源
      houseOptions: [],
      //商品
      goodsList: [],
      //列表
      bannerList: [],
      form: {},
      rules: {
        type: [{ required: true, message: "请输入", trigger: "blur" }],
        pic_url: [{ required: true, message: "请输入", trigger: "blur" }],
        link_type: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadArea();
    this.loadData();
  },
  methods: {
    /**
     * 获取城市
     */
    loadArea() {
      this.$http.get("admin/v1/common/search_area").then((res) => {
        if (res.code == 0) {
          this.areaList = res.data;
        }
      });
    },

    /**
     * 加载板块数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("admin/v1/AppletBanner?areaId=" + this.area_id ?? "")
        .then((res) => {
          if (res.code == 0) {
            this.bannerList = res.data;
          }
          this.loading = false;
        });
    },

    /**
     * 城市更改选择
     */
    areaChange() {
      //   this.current_version = this.versionList.find(
      //     (x) => x.id == this.version_id
      //   );
      this.loadData();
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.title = item ? "修改轮播" : "新增轮播";
      this.form = item ? Object.assign({}, item) : {};
      this.dialogVisible = true;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;
      this.$http.post("admin/v1/AppletBanner/edit", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
        this.saving = false;
        this.dialogVisible = false;
      });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("admin/v1/AppletBanner/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },
    /**
     * 上传回调
     */
    uploadChange(url) {
      this.form.pic_url = url;
    },
    /**
     * 关键字搜索房源
     */
    remoteHouse(q) {
      this.$http.get("admin/v1/common/house?q=" + q).then((res) => {
        if (res.code == 0) {
          this.houseOptions = res.data;
        }
      });
    },
  },
};
</script>
<style >
</style>